/* eslint-disable react/no-danger */
import Script from 'next/script';

import { useMemo } from 'react';

const JsonLdScript = ({ scriptId, type, jsonLd }) => {
  return useMemo(() => {
    const json = {
      '@context': 'https://schema.org',
      '@type': type,
      ...jsonLd
    };

    return (
      <Script
        strategy="beforeInteractive"
        type="application/ld+json"
        key={`schema:${scriptId}`}
        id={`schema:${scriptId}`}
        dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
      />
    );
  }, []);
};

JsonLdScript.defaultProps = {
  scriptId: 'application-ld-json'
};

export default JsonLdScript;
